import FlashLib from 'flashlib_onlyplay';
import eStonesType from './eStonesType';
import EntryPoint from 'Engine/EntryPoint';
import eServerClientStone from './eServerClientStone';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from '../../../model/GameModel';

export default class ControllerJackpotGamePanels extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.icons = [0, 0, 0, 0, 0];
    this.init();
    this.addListeners();
  }

  init() {
    this.panels = {}
    for (const key in eStonesType) {
      const panel = this.getChildByName(`panel_${eStonesType[key]}`)
      panel.type = eStonesType[key];
      panel.initAnimation();
      this.panels[eStonesType[key]] = panel;
    }
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    GlobalDispatcher.add('plate: openComplete', this.onOpenComplete, this)
  }

  onResize(data) {
    const frame = data.isMobile && data.isPortrait ? 2 : 1;
    this.goToFrame(frame);
    this.init();
    this.update();
  }

  onOpenComplete({ params: stoneIndex }) {
    this.icons[stoneIndex]++;
    this.panels[eServerClientStone[stoneIndex]].showNIcons(this.icons[stoneIndex]);
    this.panels[eServerClientStone[stoneIndex]].showAnimation(this.icons[stoneIndex] === 3);
  }

  update() {
    const jackpotList = EntryPoint.GameModel.jackpotList;
    if (!jackpotList) return;
    jackpotList.forEach((jackpotValue, index) => {
      if (!this.panels[eServerClientStone[index]]) return;
      this.panels[eServerClientStone[index]].setValue(jackpotValue);
      this.panels[eServerClientStone[index]].showNIcons(this.icons[index]);
    })
  }

  reset() {
    this.icons = GameModel.jackpotResults;
    this.update();
  }

}
