export  default  {
  EST_BACKGROUND: 'SFX',
  EST_REEL_STOP: 'ReelStop',
  EST_SCATTER_STOP: 'ScatterStop',
  EST_BIG_WIN: 'BigWin',
  EST_MEGA_WIN: 'MegaWin',
  EST_EPIC_WIN: 'EpicWin',
  EST_BUTTON_CLICK: 'ButtonClick',
  EST_MONEY_GAIN: 'MoneyGain',
  EST_TILE: 'Tile',
  EST_LIGHT_WIN: 'lightWin',

  EST_JACKPOT_GAME_BACKGROUND: 'jackpotGame/background',
  EST_JACKPOT_GAME_CLICK_ON_PLATE: 'jackpotGame/clickOnPlate',
  EST_JACKPOT_GAME_JACKPOT: 'jackpotGame/jackpot',
  EST_JACKPOT_GAME_PLATE_DESTROYED: 'jackpotGame/plateDestroyed',
}
