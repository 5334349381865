const uiColors = {
  overlay: {
    default: 'rgba(51, 0, 18, 0.9)',
  },
  primary: {
    default: '#FF0F0099',
    hover: '#FF847C99',
    pressed: '#9A0000CC',
    disabled: '#FF675E99',
  },
  secondary: {
    default: '#FF6B00CC',
    hover: '#FF9E45CC',
    pressed: '#CB4900CC',
  },
  toggled: {
    default: '#F00073',
  },
  cancel: {
    default: '#F11C5C',
    hover: '#FF2C6B',
    pressed: '#E00043',
  },
  panel: {
    default: '#860B0066',
  }
};


const uiConfig = {
  H: {
    colors: uiColors,
    offsets: {
      right_panel: { top: 0, left: -11 },
      bottom_panel: { top: 0, left: 0 },
    },
  },
  V: {
    colors: uiColors,
    offsets: {
      top: -30,
      left: 0,
      tournaments_panel: {
        top: -147,
        left: 0,
      }
    },
  }
}

export {
  uiConfig,
}
