import FlashLib from 'flashlib_onlyplay';
import { winFontStyle } from '../../../fontStyles';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import WinAmountAnimated from '../../../winAmountAnimated/winAmountAnimated';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import StatesManager from 'Engine/base/states/StatesManager';
import eSoundType from '../../../sounds/eSoundType';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../../enums/eAnimationTypes';
import { gsap } from 'gsap';
import SoundManager from 'Engine/soundManager/SoundManager';
import eBigWinTypes from '../../../enums/eBigWinTypes';
import eEventTypes from '../../../enums/eEventTypes';
import { eBigWinAnimationNames, eEpicWinAnimationNames, eMegaWinAnimationNames } from '../../../enums/eAnimationNames';
import GameModel from '../../../model/GameModel';

export default class PopupWin extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.eventMode = 'static';

    this.stepsData = {
      [eBigWinTypes.EBWT_BIG_WIN]: { animationName: eBigWinAnimationNames, sound: eSoundType.EST_BIG_WIN },
      [eBigWinTypes.EBWT_MEGA_WIN]: { animationName: eMegaWinAnimationNames, sound: eSoundType.EST_MEGA_WIN},
      [eBigWinTypes.EBWT_EPIC_WIN]: { animationName: eEpicWinAnimationNames, sound: eSoundType.EST_EPIC_WIN},
    }

    this.stepsSequence = [
      eBigWinTypes.EBWT_BIG_WIN,
      eBigWinTypes.EBWT_MEGA_WIN,
      eBigWinTypes.EBWT_EPIC_WIN,
    ]

    this.animationCycleTime = 2000;
    this.animationStack = [];
    this.animationListener = {
      complete: this.executeAnimation.bind(this)
    };

    this.decimals = GameModel.currencyInfo.decimals;

    this.addListeners();
    this.init();
    this.initAnimation();
  }

  init() {
    this.visible = false;
    this.overlay = this.getChildByName('overlay');
    this.overlay.hide();
    this.smallWinContainer = this.getChildByName('smallPanel');
    this.smallWinContainer.visible = false;
    this.smallWinAmount = this.smallWinContainer.getChildByName('amount');

    this.bigWinContainer = this.getChildByName('bigPanel');
    this.bigWinContainer.visible = false;
    this.simpleAmount = this.bigWinContainer.getChildByName('simpleAmount');
    this.coef = this.bigWinContainer.getChildByName('coef');
    this.totalAmount = this.bigWinContainer.getChildByName('totalAmount');

    this.setTextsStyle();
    this.smallWinAmount = this.smallWinAmount.toBmText([...PIXI.BitmapFont.NUMERIC,'.']);
    this.simpleAmount = this.simpleAmount.toBmText([...PIXI.BitmapFont.NUMERIC,'.']);
    this.coef = this.coef.toBmText([...PIXI.BitmapFont.NUMERIC, '×']);
    this.totalAmount = this.totalAmount.toBmText([...PIXI.BitmapFont.NUMERIC,'.']);

    this.smallWinCounter = new WinAmountAnimated(this.smallWinAmount);
    this.simpleWinCounter = new WinAmountAnimated(this.simpleAmount);
    this.totalWinCounter = new WinAmountAnimated(this.totalAmount);  }

  setTextsStyle() {
    this.smallWinAmount.style = { ...this.smallWinAmount.style,  ...winFontStyle}
    this.simpleAmount.style = { ...this.simpleAmount.style,  ...winFontStyle}
    this.coef.style = { ...this.coef.style,  ...winFontStyle}
    this.totalAmount.style = { ...this.totalAmount.style,  ...winFontStyle}
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__END, this.complete, this);
    GlobalDispatcher.add(eEventTypes.EET_SHOW_WIN_STATE__START, this.show, this);
    GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__START, this.stop, this);
    if (!window.OPWrapperService.config.skipBlocked) this.on('pointertap', () => StatesManager.goToNextState());
  }

  initAnimation() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_BIG_WIN);
    this.animation.visible = false;
    this.getChildByName('animationContainer').addChild(this.animation);
  }

  show() {
    this.visible = true;
    this.eventMode = 'static';
    this.alpha = 1;
    this.showBigWin(EntryPoint.GameModel.winDuration);
  }

  stop() {
    if (this.animationStack.length) {
      this.animation.visible = true;
      const animationName = this.animationStack[this.animationStack.length - 1].name;
      this.animation.state.setAnimation(0, animationName, true);
      this.overlay.show();
      GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
      this.animationStack = [];
    }
    this.executeAnimation()
    this.smallWinCounter.stop();
    this.totalWinCounter.stop();
    this.simpleWinCounter.stop();
    this.simpleWinCounter.set(EntryPoint.GameModel.totalWin  / EntryPoint.GameModel.extraCoef);
    this.totalWinCounter.set(EntryPoint.GameModel.totalWin);
    this.smallWinCounter.set(EntryPoint.GameModel.totalWin);
    clearTimeout(this.soundTimeout);
    clearTimeout(this.timeout);
    this.animation.state.clearListeners();
    if (!this.isLightWin) {
      SoundManager.stop(eSoundType.EST_MONEY_GAIN);
      SoundManager.play(eSoundType.EST_TILE, 0.5, false, 'winning');
    }
  }

  complete() {
    this.hide();
  }

  hide() {
    this.eventMode = 'none';
    this.playMainBackgroundSound();
    gsap.to(this, { alpha: 0, duration: 0.4, onComplete: () =>{
        this.visible = false;
        this.animation.visible = false;
        this.smallWinContainer.visible = false;
        this.bigWinContainer.visible = false;
        this.animationStack = [];
        this.overlay.hide();
      }})
  }

  showBigWin(duration) {
    this.stopMainBackgroundSound();

    this.winDataIndex = this._getWinDataIndex();
    this.isLightWin = EntryPoint.GameModel.totalWin <= EntryPoint.GameModel.bet;

    this.createAnimationStack(this.winDataIndex);

    this.startAnimatedValue = 0;
    this.nextAnimatedValue = this.animationStack.length ? EntryPoint.GameModel.totalWin / (this.animationStack.length + 2) * 2 : EntryPoint.GameModel.totalWin;
    this.animationCycleTime = duration / (this.animationStack.length + 2) * 2 ;

    if (EntryPoint.GameModel.extraCoef > 1) {
      this.bigWinContainer.visible = true;
      this.simpleWinCounter.animate(this.startAnimatedValue / EntryPoint.GameModel.extraCoef, this.nextAnimatedValue / EntryPoint.GameModel.extraCoef, this.decimals, this.animationCycleTime);
      this.coef.text = `×${EntryPoint.GameModel.extraCoef}`;
      this.totalWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime);
      this.totalWinCounter.onStop = this.showBigWinAnimation.bind(this);
    } else {
      this.smallWinContainer.visible = true;
      this.smallWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime);
      this.smallWinCounter.onStop = this.showBigWinAnimation.bind(this);
    }

    if (this.isLightWin) {
      SoundManager.play(eSoundType.EST_LIGHT_WIN, 0.5, false, 'winning')
    } else if (this.animationStack[this.animationStack.length - 1]) {
      const soundAlias = this.animationStack[this.animationStack.length - 1].sound;
      SoundManager.play(soundAlias, 0.5, false, 'winning')
      const offset = 900;
      this.soundTimeout = setTimeout(
        () =>SoundManager.play(eSoundType.EST_MONEY_GAIN, 0.5, true, 'winning'),
        SoundManager.getSound(soundAlias).duration * 1000 - offset)
    } else {
      SoundManager.play(eSoundType.EST_MONEY_GAIN, 0.5, true, 'winning')
    }
  }

  showBigWinAnimation() {
    this.totalWinCounter.onStop = () => {};
    this.smallWinCounter.onStop = () => {};
    this.startAnimatedValue = this.nextAnimatedValue;
    this.nextAnimatedValue = EntryPoint.GameModel.totalWin / (this.animationStack.length ) * 2;
    this.executeAnimation();
  }

  createAnimationStack(index) {
    this.stepsSequence.forEach((bigWinType, i) => {
      if (i <= index) {
        const el = this.stepsData[bigWinType];
        const start = { name: el.animationName.START, type: 'start', sound: el.sound, coef: EntryPoint.GameModel.winRankCoef[bigWinType] };
        const idle = { name: el.animationName.IDLE, type: 'idle', sound: el.sound, coef: EntryPoint.GameModel.winRankCoef[bigWinType] };

        this.animationStack = [...this.animationStack, start, idle];
      }
    });
  }

  executeAnimation() {
    this.timeout && clearTimeout(this.timeout);
    if (!this.animationStack.length) {
      GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP);
      return setTimeout(() => GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP_COMPLETED), 2000);
    }

    const animation = this.animationStack.shift();
    let loop = false;

    if (animation.type === 'start') {
      GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
      this.overlay.show();
      this.animateWinning();
      this.animation.state.addListener(this.animationListener);
    }

    if (animation.type === 'idle') {
      loop = true;
      this.startAnimatedValue = this.nextAnimatedValue;
      this.nextAnimatedValue = EntryPoint.GameModel.totalWin / this.animationStack.length * 2;
      this.setIdleTimeout();
    }
    this.animation.visible = true;
    this.animation.state.setAnimation(0 ,animation.name, loop);
  }

  animateWinning() {
    if (EntryPoint.GameModel.extraCoef > 1) {
      this.simpleWinCounter.animate(this.startAnimatedValue / EntryPoint.GameModel.extraCoef, this.nextAnimatedValue / EntryPoint.GameModel.extraCoef, this.decimals, this.animationCycleTime);
      this.totalWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime);
    } else {
      this.smallWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime);
    }
  }

  setIdleTimeout() {
    this.animation.state.clearListeners();
    this.timeout = setTimeout(() => {
      this.executeAnimation();
    }, this.animationCycleTime - 1000);
  }

  _getWinDataIndex() {
    let winDataIndex = [...this.stepsSequence].reverse().findIndex((bigWinType) => {
      return EntryPoint.GameModel.winRankCoef[bigWinType] * EntryPoint.GameModel.bet <= EntryPoint.GameModel.totalWin;
    });
    winDataIndex = winDataIndex === -1 ? - 1 : (this.stepsSequence.length - 1) - winDataIndex;
    return winDataIndex;
  }

  stopMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline()
    gsap.killTweensOf(backgroundMainGameSound);
    tween
      .to(backgroundMainGameSound, { volume: 0.15, duration: 0.5})
  }

  playMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    gsap.killTweensOf(backgroundMainGameSound);
    const tween  = gsap.timeline();
    tween
      .to(backgroundMainGameSound, { volume: 0.5, duration: 1.5 });
  }
}

