import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';
import eEventTypes from '../../enums/eEventTypes';

export default class SymbolsFrames extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);
        this.visible = false;

        this.initAnimation()
        this.addListeners();
    }

    addListeners() {
        GlobalDispatcher.add(eEventTypes.EET_JACKPOT_GAME_IN__START, this.hideTemporarily, this);
        GlobalDispatcher.add(eEventTypes.EET_JACKPOT_GAME_OUT__START, this.tryShow, this);
    }

    initAnimation() {
        this.animation = animationCreator.createAnimation(eAnimationTypes.SYMBOL_FRAME);
        this.animation.visible = false;
        this.addChild(this.animation);
    }

    start() {
        this.visible = true;
        this.animation.visible = true;
        this.animation.state.setAnimation(0, eAnimationNames.EAN_SYMBOL_FRAME, true);
    }

    stop() {
        if (!this.visible) return;
        this.visible = false;
        this.animation.visible = false;
    }

    hideTemporarily() {
        this.isShowAfterTemporarilyHide = this.animation.visible;
        setTimeout(this.stop.bind(this), 2000);
    }

    tryShow() {
        this.isShowAfterTemporarilyHide && this.start();
    }
}
