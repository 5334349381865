import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SlotGameModel from 'Engine/slots/model/SlotGameModel';
import eBigWinDefaultCoefs from '../enums/eBigWinDefaultCoefs';
import eBigWinTypes from '../enums/eBigWinTypes';
import eEventTypes from '../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';
import eSymbolsAnimationDurations from '../enums/eSymbolsAnimationDurations';
import ServerManager from '../server/ServerManager';

export default new class GameModel extends SlotGameModel {
  constructor() {
    super();
    this._freespins = 0;
    this._freespinsTotalWin = 0;
    this._freespinsTriggered = false;
    this._featureList = [];
    this._winDuration = 0;

    GlobalDispatcher.addOnce(eEventTypes.EET_DATA_UPDATED,() => {
      this._winRankCoef = {
        [eBigWinTypes.EBWT_BIG_WIN]:  OPWrapperService.config.minWinRankCoef ? OPWrapperService.config.minWinRankCoef : eBigWinDefaultCoefs.EBWC_BIG_WIN,
        [eBigWinTypes.EBWT_MEGA_WIN]: eBigWinDefaultCoefs.EBWC_MEGA_WIN,
        [eBigWinTypes.EBWT_EPIC_WIN]: eBigWinDefaultCoefs.EBWC_EPIC_WIN
    };
    }, this);
  }

  init() {
    super.init();
    ServerManager.onInit();
  }

  setNewData(data) {
    super.setNewData(data);
    if (data.spin) {
      this._extraReels = data.spin.extraReels || this._extraReels;
      this._extraCoef = data.spin.extraCoef || 1;

      this._isBonusGame = data.bonusGame && data.bonusGame.isBonusGame;

      let linesLength = this.winnings.lines && this.winnings.lines.length;
      if (this.winnings.scatters) linesLength += this.winnings.scatters[0].count ** 1.8;
      this._winDuration = eSymbolsAnimationDurations.ESAD_DEFAULT * (linesLength) * (1 + (this.extraCoef - 1) / 9) || 0;
    }
      if (data.jackpotGame) {
      this._winJackpotType = data.jackpotGame.winJackpotType;
      this._jackpotWinAmount = data.jackpotGame.jackpotWinAmount;
      if (this._jackpotWinAmount) window.OPWrapperService.ControllerStatistic.win = this._jackpotWinAmount;
      this._jackpotField = data.jackpotGame.field || [[-1,-1,-1],[-1,-1,-1],[-1,-1,-1],[-1,-1,-1],[-1,-1,-1]];
      this._jackpotResults = data.jackpotGame.results || [0,0,0,0,0];
      if (data.jackpotGame.bet && !this._freebetsIsActive) this.bet = data.jackpotGame.bet;
    }
    this._jackpotCoeffs = data.jackpotCoeffs || this._jackpotCoeffs;
    this._isJackpotGame = data.jackpotGame && data.jackpotGame.isJackpotGame;
    GlobalDispatcher.dispatch(eEventTypes.EET_DATA_UPDATED, data.action);
  }

  changeBet(index) {
    if (index >= this._betPossible.length) index = this._betPossible.length - 1;
    if (index < 0) index = 0;

    this.betIndex = index;
  }

  get paytableCurrentInfo() {
    this._paytableCurrentInfo = {}
    for (const index in this._paytableInfo) {
      this._paytableCurrentInfo[index] = this._paytableInfo[index].map(coef => {
        if (index === '8') return coef * this.bet;
        return coef * this.bet / EntryPoint.configData.lines.count;
      })
    }
    this._paytableCurrentInfo['wildField'] = this._paytableInfo[1][4] * this.bet
    this._paytableCurrentInfo['wildFieldPlusChili'] = this._paytableCurrentInfo['wildField'] * 9

    return this._paytableCurrentInfo
  }

  get winRankCoef () {
    return this._winRankCoef;
  }

  get extraReels() {
    return this._extraReels;
  }

  get extraCoef() {
    return this._extraCoef;
  }

  get winDuration() {
    return this._winDuration;
  }

  get isJackpotGame() {
    return this._isJackpotGame;
  }
  get jackpotField() {
    return this._jackpotField;
  }
  get jackpotResults() {
    return this._jackpotResults;
  }
  get winJackpotType() {
    return this._winJackpotType;
  }
  get jackpotWinAmount() {
    return this._jackpotWinAmount;
  }
  get jackpotList() {
    return this._jackpotCoeffs.map(coef => this.bet * coef);
  }
}
