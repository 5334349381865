export default {
  ESI_WILD: 1,
  ESI_KALYNA: 2,
  ESI_SABERS: 3,
  ESI_ROOSTER: 4,
  ESI_PALYANITSYA: 5,
  ESI_PYSANKA: 6,
  ESI_WHEET: 7,
  ESI_SCATTER: 8,
}
