import FlashLib from "flashlib_onlyplay";
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';

export default class BackgroundContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);
        this.addListeners();
    }

    addListeners() {
        window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );
    }

    onResize (data) {
        if (data.isLandscape) {
            this.goToFrame(2)
            this.isPortrait = false;
        } else if (data.isMobile && data.isPortrait) {
            this.goToFrame(1)
            this.isPortrait = true;
        }
    }

}
