import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { startScreenFontStyle, startScreenWithStrokeFontStyle } from '../../fontStyles';
import eEventTypes from '../../enums/eEventTypes';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import LocalizeTextField from 'Engine/base/localization/LocalizeTextField';

export default class PaytableContent extends FlashLib.MovieClip{
  constructor(data, displayData) {
    const symbolName = data.timeline.name;
    const PaytableJackpotContentData = FlashLib.getItemDataFromLibrary(`jackpotMovieClips/paytable/${symbolName}`, 'jackpotGameFlashLib');
    data.timeline.frameCount = PaytableJackpotContentData.timeline.frameCount;
    data.timeline.layers[0].frameCount = PaytableJackpotContentData.timeline.layers[0].frameCount;
    data.timeline.layers[0].frames.push(PaytableJackpotContentData.timeline.layers[0].frames[3]);
    super(data, displayData);

    this.currentFrame = 1;
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE__CHANGE_PAGE, this.changeFrame, this);
  }

  setMultipliersStyle() {
    switch (this.currentFrame) {
      case 1:
        const coefStyle = EntryPoint.GameModel.currencyInfo.decimals <= 3
          ? startScreenWithStrokeFontStyle
          : { ...startScreenWithStrokeFontStyle, fontSize: 38, lineHeight: 62 };

        for (let i = 1; i <= 8; i++) {
          const coefs = this.children[0].getChildByName(`paytable_p1_coefs${i}`)
          if (coefs) {
            coefs.style = { ...coefs.style, ...coefStyle }
          }
        }
        const wildFieldCoef = this.children[0].getChildByName('paytable_wild_coef');
        const wildFieldPlusChiliMaxCoef = this.children[0].getChildByName('paytable_chili_coef');
        wildFieldCoef.style = { ...wildFieldCoef.style, ...startScreenWithStrokeFontStyle}
        wildFieldPlusChiliMaxCoef.style = { ...wildFieldPlusChiliMaxCoef.style, ...startScreenWithStrokeFontStyle}
        break;
    }
  }

  setMultipliers() {
    switch (this.currentFrame) {
      case 1:
        const showCurrency = true;
        for (let i = 1; i < Object.keys(EntryPoint.GameModel.paytableInfo).length; i++) {
          let element = this.children[0].getChildByName(`paytable_p1_coefs${i}`);
          if (element) {
            let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[i]];
            amounts = amounts.slice(2, amounts.length);
            amounts = amounts.map((amount, index) => (index + 3) + ' - ' +  MoneyFormat.format(amount, showCurrency))
            amounts.reverse();
            element.text = amounts.join('\n\n');
            element.correctPosition = function () {
              LocalizeTextField.prototype.correctPosition.call(this, 'center', 'center')
            };
            element.fitSize(true);
          }
        }
        const wildFieldCoef = this.children[0].getChildByName('paytable_wild_coef');
        wildFieldCoef.text = MoneyFormat.format(EntryPoint.GameModel.paytableCurrentInfo['wildField'], showCurrency);
        wildFieldCoef.fitSize(true);
        wildFieldCoef.correctPosition = function () {
          LocalizeTextField.prototype.correctPosition.call(this, 'center', 'center')
        };
        const wildFieldPlusChiliMaxCoef = this.children[0].getChildByName('paytable_chili_coef');
        wildFieldPlusChiliMaxCoef.text = MoneyFormat.format(EntryPoint.GameModel.paytableCurrentInfo['wildFieldPlusChili'], showCurrency);
        wildFieldPlusChiliMaxCoef.fitSize(true);
        wildFieldPlusChiliMaxCoef.correctPosition = function () {
          LocalizeTextField.prototype.correctPosition.call(this, 'center', 'center')
        }        ;
        break;
      case 4:
        if (!window.OPWrapperService.config.jackpotEnabled) {
          const jackpotDesc = this.children[0].getChildByName('paytable_3_0');
          jackpotDesc.text = window.OPWrapperService.localizations.getLocalizedText('paytable_3_0_mystery_bonus')
        }

    }
  }

  setTextsStyle() {
    const text = this.children[0].getChildByName(`paytable_${this.currentFrame-1}_0`)
    if (text) {
      text.style = { ...text.style, ...startScreenFontStyle}
    }
  }

  changeFrame(event) {
    this.currentFrame = event.params || 1;
    this.goToFrame(this.currentFrame);
    this.setMultipliersStyle();
    this.setMultipliers()
    this.setTextsStyle()
  }

  destroy(){
    GlobalDispatcher.remove(eEventTypes.EET_PAYTABLE__CHANGE_PAGE, this.changeFrame, this);
    super.destroy();
  }

  goToFrame(index) {
    this.libName = index === 4 ? 'jackpotGameFlashLib' : 'GameFlashLib';
    super.goToFrame(index);
  }
}
