import ErrorManager from 'Engine/ErrorManager';
import EntryPoint from 'Engine/EntryPoint';
import Utils from 'Engine/Utils';
import * as queryString from 'querystring';

export default class XXXData {
  constructor() {
    this.adress = window.location.origin;

    this._tryGetSessionForDev();

    this.sid = Utils.getUrlParam('sid');
    this.gid = Utils.getUrlParam('gid');

    this.cheatsParams = ['force_reels', 'force_jackpot', 'force_mini_reels'];
  }

  async sendInit() {
    const data = await this.sendMessage({}, `game_init`, () => {}, 'GET', 'game_init');
    await window.OPWrapperService.init({
      partnerConfig: data.partner_config,
      language: data.lang,
      tournaments: data.tournaments,
      currencyInfo: {
        currency: data.currency,
        decimals: data.decimals,
      },
    });
    window.OPWrapperService.freeBetsController.setData(data.freebets);
    return await this.sendMessage({}, 'config');
  }

  async sendMessage(data, action, callback = () => {}, method = 'POST', urlPath = 'game_request') {
    // Construct the URL with query parameters
    let url = `${this.adress}${urlPath}?sid=${this.sid}&gid=${this.gid}`;

    // Adding mandatory fields to the data object
    data.id = action;
    data.ver = '1.1';

    // Inject cheat parameters if applicable
    this._trySendCheatParams(data);

    // Configuration object for the fetch call
    const config = {
      method: method,
      ...(method === 'POST' && { body: JSON.stringify(data) })
    };

    let retryCount = 0;

    // Function to perform the fetch operation
    const send = async () => {
      try {
        const response = await fetch(url, config);
        const jsonResponse = response.ok && await response.json();

        // Check for session expiration and retry logic
        if (['game_init', 'config'].includes(action) && (!response.ok || jsonResponse.code === window.OPWrapperService.errors.SESSION_EXPIRED.CODE) && retryCount < 2) {
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 1000)); // Delay before retrying
          return send();
        } else if (response.ok) {
          return jsonResponse;
        } else {
          let body;
          try {
            body = await response.text();
          } catch (e) {

          }
          throw new Error(JSON.stringify({
            status: response.status,
            statusText: response.statusText,
            body: body
          }))
        }
      } catch (error) {
        console.error(error);
        window.OPWrapperService.showError(window.OPWrapperService.errors.REQUEST_FAILED.CODE);
        throw error;
      }
    };

    // Executing the send function and handling the callback
    try {
      const result = await send();
      const parsedResult = ErrorManager.parseResponseError(result);
      callback(parsedResult);
      return parsedResult;
    } catch (error) {
      if (error.name === 'OPWrapperError') {
        throw error;
      } else {
        console.error(error);
        window.OPWrapperService.showError(window.OPWrapperService.errors.REQUEST_FAILED.CODE);
      }
    }
  }

  _trySendCheatParams(data) {
    for (const cheatsParam of this.cheatsParams) {
      if (window[cheatsParam]) {
        data[cheatsParam] = window[cheatsParam];
        delete window[cheatsParam];
      }
    }
  }

  _tryGetSessionForDev() {
    const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
    if (devEnvironments.some(regexp => regexp.test(this.adress))) {
      const queryParams = queryString.parse(window.location.search.substr(1));
      if (!Object.keys(queryParams).length) {
        this._getSession();
      }
      this.adress = `https://${Utils.getUrlParam('api')}/`
    } else if (/192.168/.test(this.adress)) {
      this.adress = ''
    } else {
      this.adress = location.protocol + '//' + Utils.getUrlParam('api') + '/';
    }
  }

  async _getSession() {
    try {
      const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=hotandspicyjackpot&no_redirect&no_op_launcher', {
        method: 'GET',
      });
      const response = await raw.json();
      if (response.url) {
        const searchParams = response.url.split('?')[1];
        window.location = `${window.location.origin}?${searchParams}`;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
