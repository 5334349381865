import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import animationCreator from 'Engine/animations/animationCreator';
import eStonesType from './eStonesType';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import eAnimationTypes from '../../../enums/eAnimationTypes';

const animationsOffsets = {
  [eStonesType.EST_BLUE]: {x: 0 , y: 0.5},
  [eStonesType.EST_GREEN]: {x: -0.5 , y: 0.5},
  [eStonesType.EST_PURPLE]: {x: 0 , y: 0.5},
  [eStonesType.EST_RED]: {x: 0 , y: 0.5},
  [eStonesType.EST_YELLOW]: {x: -0.5 , y: 0.5}
}

export default class ControllerPanel extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
  }
  init() {
    this.miniStone = this.getChildByName('miniStone');
    this.microIcons = [];
    for (let i = 0; i < 3; i++) {
      const icon = this.getChildByName(`micro_${i}`)
      icon.visible = false;
      this.microIcons.push(icon);
    }
    this.amount = this.getChildByName('totalAmount');
    this.setValue(0);
  }

  initAnimation() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_JACKPOT)
    this.animation.x = this.miniStone.displayData.width / 2 + animationsOffsets[this.type].x;
    this.animation.y = this.miniStone.displayData.height / 2 + animationsOffsets[this.type].y;
    this.animation.visible = false;
    this.animation.state.addListener({
      complete: () => {
        this.miniStone.children[0].visible = true; //static stone
        setTimeout(()=>this.animation.visible = false)
        if (this.isEndJackpot) {
          GlobalDispatcher.dispatch('panel: maxIcons')
          this.isEndJackpot = false;
        }
      }
    });
    this.miniStone.addChild(this.animation);
  }

  showAnimation(isEndJackpot) {
    this.animation.visible = true;
    this.miniStone.children[0].visible = false; //static stone
    this.animation.state.setAnimation(0, `mini_${this.type}_mini`,false)
    this.isEndJackpot = isEndJackpot;
  }

  getNextIconContainer() {
    const index = this.iconsAmount;
    this.iconsAmount++;
    return this.microIcons[index];
  }

  showNIcons(n) {
    this.iconsAmount = n;
    this.microIcons.forEach((icon, i) => icon.visible = i < this.iconsAmount)
  }

  setValue(value) {
    this.amount.text = MoneyFormat.format(value);
  }

  destroy(...arg) {
    if (this.isEndJackpot) {
      GlobalDispatcher.dispatch('panel: maxIcons')
      this.isEndJackpot = false;
    }
    super.destroy(...arg);
  }
}
