export const whiteYelowOrange = {
  fill: [
    'white',
    'yellow',
    '#ffa200'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.7
  ]
};

export const shadowText = {
  dropShadow: true,
  dropShadowAlpha: 0.8,
  dropShadowBlur: 4
};

export const jackpotGrand = {
  fill: "#1dfffa",
  lineJoin: "round",
  stroke: "#190060",
  strokeThickness: 5
}
export const jackpotMega = {
  dropShadow: true,
  dropShadowAngle: Math.PI / 2,
  dropShadowBlur: 6,
  dropShadowColor: "#000000",
  dropShadowDistance: 3,
  letterSpacing: 0.1,
  fill: [
    "#c9007a",
    "#ff00b0",
    "#8c004c"
  ],
  fillGradientStops: [
    0.3,
    0.55,
    0.74
  ],
  lineJoin: "round",
  stroke: "#fedd54",
  strokeThickness: 2
}
export const jackpotMajor = {
  dropShadow: true,
  dropShadowAngle: Math.PI / 2,
  dropShadowBlur: 6,
  dropShadowColor: "#000000",
  dropShadowDistance: 3,
  letterSpacing: 0.1,
  fill: [
    "#a313d6",
    "#d619ff",
    "#701493"],
  fillGradientStops: [
    0.3,
    0.55,
    0.74
  ],
  lineJoin: "round",
  stroke: "#fedd54",
  strokeThickness: 2
}
export const jackpotMinor = {
  dropShadow: true,
  dropShadowAlpha: 0.66,
  dropShadowAngle: Math.PI / 2,
  dropShadowBlur: 6,
  dropShadowColor: "#000000",
  dropShadowDistance: 3,
  letterSpacing: 0.1,
  fill: [
    "#080ec8",
    "#000cff",
    "#0c0fa7"
  ],
  fillGradientStops: [
    0.3,
    0.55,
    0.74
  ],
  lineJoin: "round",
  stroke: "#fedd54",
  strokeThickness: 2
}
export const jackpotMini = {
  dropShadow: true,
  dropShadowAlpha: 0.82,
  dropShadowAngle: Math.PI / 2,
  dropShadowBlur: 6,
  dropShadowColor: "#000000",
  dropShadowDistance: 3,
  letterSpacing: 0.1,
  fill: [
    "#ee8434",
    "yellow",
    "yellow",
    "#ff7502"
  ],
  fillGradientStops: [
    0.25,
    0.45,
    0.65,
    0.85
  ],
  lineJoin: "round",
  stroke: "#d9da2d"
}
export const startScreenFontStyle = {
  dropShadow: true,
  dropShadowAlpha: 0.82,
  dropShadowAngle: 1.5,
  dropShadowBlur: 3,
  dropShadowDistance: 1,
  fill: [
    "#ffae5b",
    "#f4a918",
    "#fccf02",
    "#fccf02"
  ],
  fillGradientStops: [
    0.18,
    0.45,
    0.6,
    1
  ],
  lineJoin: "round",
}
export const startScreenWithStrokeFontStyle = {
  dropShadow: true,
  dropShadowAlpha: 0.82,
  dropShadowAngle: 1.5,
  dropShadowBlur: 3,
  dropShadowDistance: 1,
  fill: [
    "#ffae5b",
    "#f4a918",
    "#fccf02",
    "#fccf02"
  ],
  fillGradientStops: [
    0.18,
    0.45,
    0.6,
    1
  ],
  lineJoin: "round",
  stroke: "#4a0000",
  strokeThickness: 4
}
export const startScreenMaxWinFontStyle = {
  dropShadow: true,
  dropShadowBlur: 10,
  dropShadowColor: "#7f0305",
  dropShadowDistance: 0,
  fill: [
    "#fff64e",
    "#feffef",
    "#e29302",
    "#e29302",
    "#fcea28",
    "#f5c10b"
  ],
  fillGradientStops: [
    0.18,
    0.4,
    0.5,
    0.53,
    0.62,
    0.8
  ],
  lineJoin: "round",
  stroke: "#570000",
  strokeThickness: 6
}
export const wonJackpotGameFontStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 6,
  dropShadowColor: "#770707",
  dropShadowDistance: 10,
  fill: [
    "#f98c01",
    "#ffdc1c"
  ],
  lineJoin: "round",
  stroke: "#feffdf",
  strokeThickness: 2
}
export const winFontStyle = {
  dropShadow: true,
  dropShadowAngle: 0,
  dropShadowBlur: 2,
  dropShadowColor: "#4c0000",
  dropShadowDistance: 0,
  fill: [
    "#f94803",
    "red",
    "yellow",
    "#ffff8e",
    "yellow",
    "red"
  ],
  fillGradientStops: [
    0.22,
    0.28,
    0.39,
    0.6,
    0.72,
    0.84
  ],
  lineJoin: "round",
  stroke: "white",
  strokeThickness: 3
}
