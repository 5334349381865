import eSymbolIndex from '../controllers/symbols/eSymbolIndex';

export default {
  EAN_BACKGROUND__LANDSCAPE: 'bg_heat_anim',
  EAN_POPUP_BACKGROUND: 'sample_pop-up',
  EAN_SYMBOL_FIRE: 'fire_back',
  EAN_SYMBOL_FRAME: 'winframes',
}

export const eSymbolsAnimationNames = {
  [eSymbolIndex.ESI_WILD]: 'girl',
  [eSymbolIndex.ESI_PALYANITSYA]: 'palyanitsya',
  [eSymbolIndex.ESI_KALYNA]: 'kalyna',
  [eSymbolIndex.ESI_WHEET]: 'wheet',
  [eSymbolIndex.ESI_PYSANKA]: 'pysanka',
  [eSymbolIndex.ESI_SABERS]: 'sabers',
  [eSymbolIndex.ESI_ROOSTER]: 'rooster',
  [eSymbolIndex.ESI_SCATTER]:'scatter_hawk',
}

export const eBigWinAnimationNames = {
  START: 'big_win_start',
  IDLE: 'big_win_idle'
}
export const eMegaWinAnimationNames = {
  START: 'mega_win_start',
  IDLE: 'mega_win_idle'
}
export const eEpicWinAnimationNames = {
  START: 'epic_win_start',
  IDLE: 'epic_win_idle'
}
