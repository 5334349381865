import en from '../../assets/localization/en.json';
import enSocial from '../../assets/localization/en-social.json';
import eEventTypes from '../enums/eEventTypes';
import eSoundType from '../sounds/eSoundType';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';

export default {
  buttons: [
    {
      type: 'settings',
      onClick: onSettingsClick,
      styles: ['settings']
    },
  ],
  bundle: 'chervonakalyna',
  onButtonClick: onWrapperButtonClick,
  localizationBundle: 'hotandspicy',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
}

function onSettingsClick() {
  GlobalDispatcher.dispatch(eEventTypes.EET_SETTINGS__CLICK);
}

function onWrapperButtonClick() {
  SoundManager.play(eSoundType.EST_BUTTON_CLICK);
}
