import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../../sounds/eSoundType';
import EntryPoint from 'Engine/EntryPoint';

export default class ControllerJackpotGame extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.visible = false;
    if (EntryPoint.GameModel.isJackpotGame) {
      this.alpha = 1;
      window.OPWrapperService.infoPanel.setGameState(
        window.OPWrapperService.config.jackpotEnabled
          ? window.OPWrapperService.infoPanel.gameStateTypes.EGST_JACKPOT_GAME
          : window.OPWrapperService.infoPanel.gameStateTypes.EGST_MYSTERY_BONUS_GAME
      )
    } else {
      this.alpha = 0
    }
    this.init();
    this.addListeners()
  }
  init() {
    this.background = this.getChildByName('background');
    this.totalWinPanel = this.getChildByName('totalWinPanel');
    this.panels = this.getChildByName('panels');
    this.field = this.getChildByName('field');
    this.field.init(this.panels);
    this.eventMode = 'static';
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );
  }

  onResize (data) {
    if (data.isLandscape) {
      this.background.goToFrame(2)
      this.totalWinPanel.anchorDefaultX();
      this.totalWinPanel.y = this.totalWinPanel.displayData.y;
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1)
      this.totalWinPanel.centringX();
      this.totalWinPanel.y = this.totalWinPanel.displayData.y + 200;
    }
  }

  show() {
    this.panels.reset();
    this.field.create();
    this.visible = true;
    this.playJackpotBackgroundSound()
    gsap.to(this, { alpha: 1, duration: 0.6});
  }

  playJackpotBackgroundSound () {
    const jackpotBackground = SoundManager.getSound(eSoundType.EST_JACKPOT_GAME_BACKGROUND);
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline();
    tween
      .to(backgroundMainGameSound, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.pause(eSoundType.EST_BACKGROUND);
        SoundManager.play(eSoundType.EST_JACKPOT_GAME_BACKGROUND, 0.5, true, 'music');
      })
      .fromTo(jackpotBackground,  {volume: 0}, {volume: 0.5, duration: 1.5 })
  }

  stopJackpotBackgroundSound() {
    const jackpotBackground = SoundManager.getSound(eSoundType.EST_JACKPOT_GAME_BACKGROUND);
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline();
    tween
      .to(jackpotBackground, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.stop(eSoundType.EST_JACKPOT_GAME_BACKGROUND)
        SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
      })
      .fromTo(backgroundMainGameSound,  {volume: 0}, {volume: 0.5, duration: 1.5 })
  }

  hide() {
    this.stopJackpotBackgroundSound()
    gsap.to(this, { alpha: 0, duration: 0.6, onComplete: () => {
        this.visible = false
        window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_REGULAR)
      } });
  }
}
